/* * @Descripttion: 用印申请 * @version: 2.2.1 * @LastEditors: 邓亚鑫 * @LastEditTime: 2022-03-24 14:09 * @version:
2.2.2 * @LastEditors: 邓亚鑫 * @LastEditTime: 2022-04-07 10:17 */ // 刘志坤 V2.5.3
<template>
  <section class="apply-add" ref="parent">
    <a-form ref="formRef" :rules="rulesRef" :model="modelRef" layout="vertical" style="margin: 24px 24px 0">
      <a-card title="基本信息" style="border-bottom: 0">
        <template #extra>
          <a-button class="add-btn" @click="addSealType"> <PlusOutlined class="add-icon" />添加印章 </a-button>
        </template>
        <a-row>
          <!-- 流程主题 -->
          <a-col :span="8">
            <a-form-item label="流程主题" name="fileName" class="form-pad">
              <a-col :span="18">
                <a-input placeholder="请输入流程主题" :maxlength="30" v-model:value="modelRef.fileName" />
              </a-col>
            </a-form-item>
          </a-col>
          <!-- 申请印章 -->
          <a-col :span="8">
            <a-form-item
              :label="'申请印章'"
              :name="['sealFlowRelAddDTOList', 0, 'sealId']"
              :rules="[
                {
                  type: 'number',
                  required: true,
                  message: '请选择申请印章'
                }
              ]"
            >
              <!-- <a-col :span="18"> -->
              <!-- <a-input :maxlength="3" v-model:value="modelRef.sealFlowRelAddDTOList[index].value" placeholder="请输入申请次数" /> -->

              <a-select
                v-model:value="modelRef.sealFlowRelAddDTOList[0].sealId"
                @change="selectChange"
                @focus="focusSelectChange(0)"
                placeholder="请选择申请印章"
                style="width: 75%"
                optionFilterProp="staff"
                :showSearch="true"
              >
                <a-select-option
                  :staff="item.sealName"
                  v-for="item in filterSealSelectList"
                  :disabled="item.disabled"
                  :key="item.sealId"
                  :value="item.sealId"
                  :item="item"
                  :index="0"
                  >{{ item.sealName }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
          <!-- 申请次数 -->
          <a-col :span="8">
            <a-form-item
              :label="'申请次数'"
              :name="['sealFlowRelAddDTOList', 0, 'applicationNumber']"
              :rules="[
                {
                  required: true,
                  message: `请输入申请次数`
                },
                {
                  pattern: /^[0-9]*$/,
                  message: '请输入数字'
                },
                {
                  validator: validatorSealApplyCount,
                  trigger: 'change'
                }
              ]"
            >
              <a-input
                style="width: 75%"
                :maxlength="3"
                v-model:value="modelRef.sealFlowRelAddDTOList[0].applicationNumber"
                placeholder="请输入申请次数"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <!-- 审批流程/盖印人 -->
        <div v-show="modelRef.sealFlowRelAddDTOList[0].isSelectSeal">
          <a-row>
            <!-- 审批流程 -->
            <a-col :span="8">
              <a-form-item
                label="审批流程"
                :name="['sealFlowRelAddDTOList', 0, 'flowId']"
                :rules="[
                  {
                    type: 'number',
                    required: true,
                    message: '请选择审批流程'
                  }
                ]"
              >
                <a-select
                  v-model:value="modelRef.sealFlowRelAddDTOList[0].flowId"
                  placeholder="请选择审批流程"
                  @change="handleProcessChange"
                  :disabled="modelRef.sealFlowRelAddDTOList[0].disabled"
                  style="width: 75%"
                >
                  <a-select-option
                    v-for="item in modelRef.sealFlowRelAddDTOList[0].approvalProcess"
                    :index="0"
                    :item="item"
                    :key="item.flowId"
                    :value="item.flowId"
                    >{{ item.flowName }}</a-select-option
                  >
                </a-select>
              </a-form-item>
            </a-col>
            <!-- 盖印人 -->
            <a-col :span="8">
              <a-form-item label="盖印人" :name="['sealFlowRelAddDTOList', 0, 'affixedStaffName']">
                <a-col :span="18">
                  <SealerPopover :list="modelRef.sealFlowRelAddDTOList[0].sealStaffRelVOList">
                    <div class="sealer-list">
                      <div class="list-box">
                        <div v-if="modelRef.sealFlowRelAddDTOList[0].sealStaffRelVOList">
                          <div
                            class="item-box"
                            v-for="(items, index) in modelRef.sealFlowRelAddDTOList[0].sealStaffRelVOList"
                            :key="items"
                          >
                            {{ items.staffName }}
                          </div>
                        </div>
                        <div v-else>暂无盖印人</div>
                      </div>
                    </div>
                  </SealerPopover>
                  <!-- <a-input :value="modelRef.sealFlowRelAddDTOList[0].affixedStaffName" disabled class="sealer-input" /> -->
                </a-col>
              </a-form-item>
            </a-col>
          </a-row>
          <!--        审批流程头像展示-->
          <userAvatarTemplate :useList="approvalStaffList[0]" />
        </div>
        <!--添加印章【卡片列表】-->
        <div v-for="(item, index) in modelRef.sealFlowRelAddDTOList" :key="index">
          <div class="content" v-if="index > 0">
            <div class="content-tab">
              <div class="content-tab">
                <a-badge
                  :count="index + 1"
                  :number-style="{
                    backgroundColor: '#C3161C',
                    color: '#ffffff'
                  }"
                />
                <div class="content-tab-title">第{{ numToZh(index + 1) }}个印章申请信息</div>
              </div>
              <div class="content-tab content-delete">
                <img class="content-delete-icon" src="@/assets/images/delete.png" />
                <div @click="handledDelete(index)" class="content-delete-title">删除</div>
              </div>
            </div>
            <!-- 多印章列表卡片 -->
            <a-row>
              <!-- 申请印章 -->
              <a-col :span="8">
                <a-form-item
                  :label="'申请印章'"
                  :name="['sealFlowRelAddDTOList', index, 'sealId']"
                  :rules="[
                    {
                      type: 'number',
                      required: true,
                      message: '请选择申请印章'
                    }
                  ]"
                >
                  <a-select
                    v-model:value="modelRef.sealFlowRelAddDTOList[index].sealId"
                    @change="selectChange"
                    style="width: 75%"
                    @focus="focusSelectChange(index)"
                    placeholder="请选择申请印章"
                  >
                    <a-select-option
                      v-for="item in filterSealSelectList"
                      :disabled="item.disabled"
                      :key="item.sealId"
                      :value="item.sealId"
                      :item="item"
                      :index="index"
                      >{{ item.sealName }}</a-select-option
                    >
                  </a-select>
                  <!-- <a-col :span="18"> -->
                  <!-- <a-input :maxlength="3" v-model:value="modelRef.sealFlowRelAddDTOList[index].value" placeholder="请输入申请次数" /> -->
                  <!--                  <a-col :span="18">-->
                  <!--                  </a-col>-->
                </a-form-item>
              </a-col>
              <!-- 申请次数 -->
              <a-col :span="8">
                <a-form-item
                  :label="'申请次数'"
                  :name="['sealFlowRelAddDTOList', index, 'applicationNumber']"
                  :rules="[
                    {
                      required: true,
                      message: `请输入申请次数`
                    },
                    {
                      pattern: /^[0-9]*$/,
                      message: '请输入数字'
                    },
                    {
                      validator: validatorSealApplyCount,
                      trigger: 'change'
                    }
                  ]"
                >
                  <a-input
                    style="width: 75%"
                    v-model:value="modelRef.sealFlowRelAddDTOList[index].applicationNumber"
                    :maxlength="3"
                    placeholder="请输入申请次数"
                  />
                </a-form-item>
              </a-col>
            </a-row>
            <div v-show="modelRef.sealFlowRelAddDTOList[index].isSelectSeal">
              <a-row>
                <!-- 审批流程 -->
                <a-col :span="8">
                  <a-form-item
                    label="审批流程"
                    :name="['sealFlowRelAddDTOList', index, 'flowId']"
                    :rules="[
                      {
                        type: 'number',
                        required: true,
                        message: '请选择审批流程'
                      }
                    ]"
                  >
                    <a-select
                      v-model:value="modelRef.sealFlowRelAddDTOList[index].flowId"
                      placeholder="请选择审批流程"
                      :disabled="modelRef.sealFlowRelAddDTOList[index].disabled"
                      @change="handleProcessChange"
                      style="width: 75%"
                    >
                      <a-select-option
                        v-for="item in modelRef.sealFlowRelAddDTOList[index].approvalProcess"
                        :index="index"
                        :key="item.flowId"
                        :item="item"
                        :value="item.flowId"
                        >{{ item.flowName }}</a-select-option
                      >
                    </a-select>
                  </a-form-item>
                </a-col>
                <!-- 盖印人 -->
                <a-col :span="8">
                  <a-form-item
                    label="盖印人"
                    :name="['sealFlowRelAddDTOList', index, 'affixedStaffName']"
                    :rules="[
                      {
                        required: true,
                        validator: isSealer,
                        trigger: 'blur'
                      }
                    ]"
                  >
                    <a-col :span="18">
                      <SealerPopover :list="modelRef.sealFlowRelAddDTOList[index].sealStaffRelVOList">
                        <div class="sealer-list">
                          <div class="list-box">
                            <div
                              class="item-box"
                              v-for="(item, index) in modelRef.sealFlowRelAddDTOList[index].sealStaffRelVOList"
                              :key="item"
                            >
                              {{ item.staffName }}
                            </div>
                          </div>
                        </div>
                      </SealerPopover>
                      <!-- <a-input
                        class="sealer-input"
                        :value="modelRef.sealFlowRelAddDTOList[index].affixedStaffName"
                        disabled
                      /> -->
                    </a-col>
                  </a-form-item>
                </a-col>
              </a-row>
              <!--        审批流程头像展示-->
              <userAvatarTemplate :useList="approvalStaffList[index]" />
            </div>
          </div>
        </div>

        <!-- 是否外带 -->
        <a-form-item
          :labelCol="{ span: 4 }"
          :wrapperCol="{ span: 14 }"
          label="是否外带"
          name="takeout"
          style="padding-top: 8px; margin-top: 16px; margin-bottom: 4px"
        >
          <a-switch checked-children="开" un-checked-children="禁" v-model:checked="modelRef.takeout" />
        </a-form-item>
        <!-- 起始时间 -->
        <a-form-item
          :labelCol="{ span: 4 }"
          :wrapperCol="{ span: 14 }"
          label="起始时间"
          name="timeRanges"
          style="padding-top: 8px"
          v-if="modelRef.takeout"
        >
          <a-range-picker
            :show-time="{
              hideDisabledOptions: true,
              format: 'HH:mm',
              defaultValue: [moment(defaultTime, 'HH:mm'), moment(defaultTime, 'HH:mm')]
            }"
            format="YYYY-MM-DD HH:mm"
            separator="→"
            :placeholder="['开始时间', '结束时间']"
            inputReadOnly
            @change="dataTimeChange"
            style="width: 310px"
            :disabled-date="disabledDate"
            :disabled-time="disabledRangeTime"
            @openChange="openChange"
            :class="checkedPick ? 'checked-pick' : ''"
            v-model:value="modelRef.timeRanges"
            ><template #suffixIcon>
              <i class="iconfont icon-Time" />
            </template>
          </a-range-picker>
        </a-form-item>
        <a-form-item
          :labelCol="{ span: 4 }"
          :wrapperCol="{ span: 14 }"
          label="外带地点"
          name="address"
          style="padding-top: 8px"
          v-if="modelRef.takeout"
        >
          <div :class="modelRef.address ? 'have-address' : 'address'" @click="gdMapVisible = true">
            <i class="iconfont icon-didian_xianxing" />
            {{ modelRef.address || '请选择外带地点' }}
          </div>
        </a-form-item>
      </a-card>
      <!-- 盖印信息 -->
      <a-card title="盖印信息" class="card-style">
        <a-form-item label="盖印方式" name="remote">
          <a-select placeholder="请选择盖印方式" class="form-input" v-model:value="modelRef.remote">
            <a-select-option v-for="item in sealingWayList" :key="item.index" :value="item.index">
              {{ item.sealWay }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <!-- 远程确认人 -->
        <a-form-item
          label="远程确认人"
          name="remoteStaffList"
          style="padding-bottom: 8px; padding-top: 8px"
          v-if="modelRef.remote === 2"
        >
          <a-button v-if="!modelRef.remoteStaffList?.length" class="add-remote" @click="remoteVisible = true">
            +
          </a-button>
          <approval-staff-time-list
            v-else
            :approvalList="modelRef.remoteStaffList"
            :showPoint="true"
            @delete-approval="deleteRemoteStaffList"
          />
        </a-form-item>
      </a-card>
      <a-card title="其他信息" class="card-style" style="padding-top: 8px">
        <a-form-item :labelCol="{ span: 4 }" :wrapperCol="{ span: 14 }" label="预计用印日期" style="padding-top: 8px">
          <a-date-picker
            v-model:value="modelRef.sealTime"
            format="YYYY-MM-DD"
            :disabled-date="disabledDate"
            valueFormat="YYYY-MM-DD"
            placeholder="请选择预计用印日期"
          >
          </a-date-picker>
        </a-form-item>
        <a-form-item :labelCol="{ span: 4 }" :wrapperCol="{ span: 14 }" label="申请事由" style="padding-top: 8px">
          <a-textarea
            :autoSize="{ minRows: 3 }"
            v-model:value="modelRef.applyText"
            placeholder="请输入申请原因"
            :maxlength="50"
            showCount
            @pressEnter="e => lineFeed(e)"
            style="width: 468px"
          />
        </a-form-item>
        <a-form-item label="上传附件">
          <div style="width: 300px">
            <lineupload
              :fileList="modelRef.documentApplyFile"
              :count="10"
              :action="action"
              :size="5"
              @fileChange="handleThumbUploadChange"
              :fileType="['pdf', 'doc', 'docx', 'jpeg', 'jpg', 'png']"
            />
          </div>
        </a-form-item>
      </a-card>
    </a-form>
    <section class="add-foot">
      <a-button @click.prevent="handleCancel" class="cancel-btn">取消</a-button>
      <a-button @click="onSubmit" :loading="loading" class="submit-btn">提交</a-button>
    </section>

    <GdMap
      v-model:gdMapVisible="gdMapVisible"
      :gdMapTitle="gdMapTitle"
      :gdMapPlaceholder="gdMapPlaceholder"
      @addressChange="addressChange"
      :selectAddress="selectAddress"
    />
    <RemoteConfirm v-model:remoteVisible="remoteVisible" :list="check.searchLbwList" @submitModel="submitModel" />
  </section>
</template>

<script>
import { SET_APPLY_PROCESS_LIST } from '@/store/globalData/mutations-type'
import { PlusOutlined } from '@ant-design/icons-vue'
import { defineComponent, reactive, toRefs, ref, onMounted, computed, watch, watchEffect, nextTick } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import lineupload from '@/components/Upload/lineUpload'
import { getApplySealList, getApplyProcessStaff, getProcessStaffList, getConfigure } from '@/apis/businessManage'
import { addApply, getEchoDetail, resubmitApply } from '@/apis/seal'
const action = process.env.VUE_APP_API_BASE_URL + '/file/upload'
import { useCheckStateAndEvent } from '@/utils/hooks1'
import ApprovalStaffTimeList from '@/views/components/approvalStaffTimeList'
import { useStore } from 'vuex'
import { cmsNotice } from '@/utils/utils'
import { numZh } from './numZh'
import GdMap from '@/components/GdMap'
import RemoteConfirm from './components/remoteConfirm'
import userAvatarTemplate from '@/views/seal/apply/components/userAvatarTemplate'
import moment, { Moment } from 'moment'
import { notification } from 'ant-design-vue'
import SealerPopover from '@/components/SealerPopover/index.vue'

export default defineComponent({
  components: {
    lineupload,
    SealerPopover,
    // VirtualList,
    // ApprovalStaffItem,
    ApprovalStaffTimeList,
    PlusOutlined,
    GdMap,
    RemoteConfirm,
    userAvatarTemplate
  },
  setup(props, ctx) {
    const { emit } = ctx
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const formRef = ref()
    const checkedPick = ref(false)
    //文件id
    const fileId = ref(0)
    const state = reactive({
      applyTypeList: [], // 申请印章列表
      uploadThumbCount: 9,
      applyTypeIndex: 0, // 选择印章的索引
      approvalStaffList: [], // 固定流程返回的审批人列表
      action: action,
      isVirtualListScroll: 0,
      isVirtualListScroll2: 0,
      applyProcessList: [], //审批流程
      allApplyProcessList: [], // 所有的审批流程
      loading: false,
      numZh,
      gdMapVisible: false,
      gdMapTitle: '选择外带地点',
      gdMapPlaceholder: '请输入外带地点',
      selectAddress: {},
      remoteVisible: false,
      // remoteStaffList: [], //确认人列表
      moment,
      defaultTime: '00:00',
      paramData: {}, //参数
      sealingWayList: [
        {
          index: 1,
          sealWay: '常规盖印'
        }
      ], //盖印方式
      sealerList: [
        { name: 'aaa' },
        { name: 'bbbbbbbbbbbbbbaaaaaaaaaaaaaaaaaaaaaaaaaaa' },
        { name: 'aaa' },
        { name: 'aaa' },
        { name: 'aaa' },
        { name: 'aaa' },
        { name: 'aaa' },
        { name: 'aaa' }
      ]
    })
    const modelRef = reactive({
      // form表单的数据
      fileName: '',
      sealFlowRelAddDTOList: [
        {
          isSelectSeal: false, //是否选择申请印章
          applicationNumber: undefined, //申请印章次数
          sealId: undefined, //印章id
          flowId: undefined, //审批流程id
          disabled: false, //是否有审批流程
          sealStaffRelVOList: [] //盖印人
        }
      ], // 申请印章list
      applyText: undefined,
      documentApplyFile: [],
      staffList: [],
      sealTime: null,
      takeout: false,
      // remote: false,
      timeRanges: [],
      address: '',
      remoteStaffId: undefined,
      remoteStaffList: [], //确认人列表
      remote: 1 //盖印方式（默认常规）
    })

    const rulesRef = reactive({
      fileName: [
        {
          required: true,
          message: '请输入流程主题'
        },
        {
          pattern: /^[\u9FA6-\u9FCB\u3400-\u4DB5\u4E00-\u9FA5_a-zA-Z0-9]{0,30}$/,
          message: '名称只能为中英文、数字、下划线组成'
        }
      ],
      type: [
        {
          type: 'number',
          required: true,
          message: '请选择用印类型'
        }
      ],
      remote: [
        {
          type: 'number',
          required: true,
          message: '请选择盖印方式'
        }
      ]
    })
    const deleteRemoteStaffList = () => {
      modelRef.remoteStaffList = []
    }

    //校验盖印人
    const isSealer = (rule, value) => {
      if (value) {
        return Promise.resolve()
      } else {
        return Promise.reject()
      }
    }
    // 禁止换行
    const lineFeed = e => {
      e.preventDefault()
    }
    const enhancerMapFn = {
      // 强化hooks函数
      deleteApprovalFn() {
        state.isVirtualListScroll2 = {}
      },

      searchApprovalList() {
        typeof state.isVirtualListScroll === 'number' ? state.isVirtualListScroll++ : (state.isVirtualListScroll = 0)
      },
      openApprovalModal() {
        initialApproalList(check.staffList)
        setTimeout(() => {
          typeof state.isVirtualListScroll === 'number' ? state.isVirtualListScroll++ : (state.isVirtualListScroll = 0)
        })
      },
      changeStaffList() {
        modelRef.staffList = check.staffList.map(item => item)
        console.log(' modelRef.staffList', modelRef.staffList)
      }
    }
    const {
      check, // 审批人的数据
      onChange, // 选择审批人change事件
      onCheckAllChange, // 全选审批人事件
      reloadSearchLbwList, // 选择完，过滤选择审批人的数据
      searchApprovalList, // 查询审批人
      deleteApprovalFn, // 删除审批人
      openApprovalModal,
      initialApproalList,
      onChangeList
    } = useCheckStateAndEvent(enhancerMapFn)

    const validatorSealApplyCount = (rule, value) => {
      if (value === '0' || value === '00' || value === '000') {
        return Promise.reject('申请次数最小为1')
      }
      return Promise.resolve()
    }
    const validatorSealApp = (rule, value) => {
      return Promise.resolve()
    }
    // 获取可使用的印章
    const getSealList = async () => {
      const res = await getApplySealList()
      if (res.code === 0) {
        state.applyTypeList = res.data
        console.log('获取可使用的印章', state.applyTypeList)
        let dataIds = state.applyTypeList.map(item => item.sealId)
        modelRef.sealFlowRelAddDTOList.forEach((item, index) => {
          if (dataIds.indexOf(item.sealId) == -1) {
            item.sealId = undefined
          }
          let currentItem = item
          console.log('currentItem', currentItem)
          state.applyTypeList.forEach(it => {
            // console.log('it', it)
            if (currentItem.sealId === it.sealId) {
              currentItem.affixedStaffName = it.affixedStaffName ? it.affixedStaffName : '暂无盖印人'
              console.log('currentItem.affixedStaffName', currentItem.affixedStaffName)
            }
          })
        })
      }
    }

    const getApplyProcessList = async () => {
      await new Promise(resolve => {
        store.dispatch(`globalData/${SET_APPLY_PROCESS_LIST}`, resolve)
      })
      getFingerProcess()
    }

    const getProcessStaff = async () => {
      // 获取自由流程的审批人
      const res = await getProcessStaffList()
      check.searchLbwList = res.data.map(item => ({ ...item, label: item.name, value: item.id }))
      check.totalSearchList = res.data.map(item => ({ ...item, label: item.name, value: item.id }))
    }
    const onSubmit = () => {
      // // 附件限制
      // if (state.paramData.ocrType === 1 && modelRef.remote == 2) {
      //   if (modelRef.documentApplyFile.length < 1) {
      //     cmsNotice('error', '当前已开启OCR功能，请上传附件用以比对')
      //     return
      //   }
      // }
      state.loading = true
      formRef.value
        .validate()
        .then(async () => {
          const params = {
            ...modelRef
          }
          console.log('params的值', params)
          params.applyText = params.applyText ? params.applyText.trim() : undefined
          params.takeout = modelRef.takeout ? 2 : 1
          delete params.timeRanges
          delete params.remoteStaffList
          // !params.documentApplyFile.length && delete params.documentApplyFil
          if (route.query.status === '2') {
            //重新提交
            params.id = fileId.value
            console.log('params', params)
            const res = await resubmitApply(params)
            if (res.success) {
              cmsNotice('success', '提交成功')
              router.push('/seal/apply')
            } else {
              state.loading = false
              console.log('res.code', res.code)
              if (res.code === 1067) {
                console.log('印章被删除！')
                // getEcho()
                const res = await getEchoDetail({ id: route.query.documentId })
                if (res.success) {
                  if (res.data.sealFlowRelAddDTOList) {
                    modelRef.sealFlowRelAddDTOList = res.data.sealFlowRelAddDTOList
                    console.log('审批流程', modelRef.sealFlowRelAddDTOList)
                    modelRef.sealFlowRelAddDTOList.forEach(function(item, index) {
                      // console.log('印章数据', item)
                      item.isSelectSeal = true
                      if (!item.flowUsers.length) {
                        item.flowId = null
                      }
                      state.approvalStaffList[index] = item.flowUsers
                      if (item.bindFlow && item.sealFlowRelVOList.length === 1) {
                        item.disabled = true
                      } else {
                        item.disabled = false
                      }
                    })

                    modelRef.sealFlowRelAddDTOList = modelRef.sealFlowRelAddDTOList.filter(it => it.flowId)
                    if (!modelRef.sealFlowRelAddDTOList.length) {
                      modelRef.sealFlowRelAddDTOList = [
                        {
                          sealId: undefined,
                          flowId: undefined,
                          applicationNumber: undefined,
                          isSelectSeal: false,
                          disabled: false
                        }
                      ]
                    }

                    console.log('多印章列表', modelRef.sealFlowRelAddDTOList)
                  }
                }
              }
              if (res['code'] === 1057) {
                modelRef.remoteStaffList = []
              }
              if (res.code === 1069) {
                //   cmsNotice('error', '用印申请已重新提交！')
                router.push('/seal/apply')
              }
              if (res['code'] === 1072) {
                getSealList()
              }
            }
          } else {
            //添加申请
            const res = await addApply(params)
            console.log('params', params)
            if (res.success) {
              cmsNotice('success', '添加成功')
              router.back()
            } else {
              state.loading = false
              if (res['code'] === 1067) {
                modelRef.sealFlowRelAddDTOList.forEach(item => {
                  item.sealId = undefined
                  item.flowId = undefined
                  item.applicationNumber = undefined
                  item.isSelectSeal = false
                  item.disabled = false
                })
                modelRef.sealFlowRelAddDTOList.splice(1, modelRef.sealFlowRelAddDTOList.length - 1)
                state.approvalStaffList = []
                await getSealList()
                await getApplyProcessList()
                console.log(modelRef.sealFlowRelAddDTOList)
              }
              if (res['code'] === 1057) {
                modelRef.remoteStaffList = []
              }
              if (res['code'] === 1072) {
                getSealList()
              }
            }
          }
          // state.loading = false
        })
        .catch(err => {
          console.log('err', err)
          state.loading = false
        })
        .finally(() => {
          console.log('finally')
        })
    }

    const ocrJurisdiction = async () => {
      const res = await getConfigure()
      // if (res.data.sealDiscern !== 2 || res.data.videoType !== 2) {
      //   state.sealingWayList.push({
      //     index: 3,
      //     sealWay: '连续盖印'
      //   })
      // }
      state.paramData = res.data
    }

    const addSealType = () => {
      if (modelRef.sealFlowRelAddDTOList.length >= 30) {
        cmsNotice('warn', '印章添加不能超过30')
        return
      }
      // 添加使用印章及次数的函数
      modelRef.sealFlowRelAddDTOList.push({
        isSelectSeal: false, //是否选择申请印章
        applicationNumber: undefined, //申请印章次数
        sealId: undefined, //印章id
        flowId: undefined, //审批流程id
        disabled: false,
        sealStaffRelVOList: [], //盖印人
        approvalProcess: [] // 可选择审批流程
      })
    }

    const handleThumbUploadChange = (type, res) => {
      console.log('接受的组件的上传文件的值', res)
      // 上传附件成功后的赋值
      // modelRef.documentApplyFile = res.map(item => ({ fileId: item.response.data[0].id }))
      modelRef.documentApplyFile = res.map(function(item, index) {
        console.log('res.item', item)
        if (item?.uid) {
          item = { name: item.name, fileId: item.response.data[0].id }
        }
        return item
      })
      console.log('上传文件modelRef.documentApplyFile的值', modelRef.documentApplyFile)
    }
    const handleProcessChange = async (val, option) => {
      console.log('选择审批流程', val, option, modelRef.sealFlowRelAddDTOList)
      console.log('盖印人数据', modelRef.sealFlowRelAddDTOList[option.index], option.item.sealStaffRelVOList)
      // 流程选择函数
      const res = await getApplyProcessStaff({ id: val })
      state.approvalStaffList[option.index] = res.data

      // modelRef.sealFlowRelAddDTOList[option.index].flowId = val
      console.log('最终数据', modelRef.sealFlowRelAddDTOList)
    }
    const handleCancel = () => {
      formRef.value.resetFields()
      router.back()
    }
    // 印章选择后，在其他中其他的要置灰
    const filterSealSelectList = computed(() => {
      const filterList = modelRef.sealFlowRelAddDTOList.filter((item, index) =>
        item ? index !== state.applyTypeIndex : false
      )
      const newApplyList = state.applyTypeList.map(item => ({ ...item }))
      filterList.forEach(item => {
        let isDisabled = true
        let num = 0
        while (isDisabled && newApplyList.length) {
          if (num === newApplyList.length - 1) isDisabled = false
          if (newApplyList[num].sealId === item.sealId) {
            isDisabled = false
            newApplyList[num].disabled = true
          }
          num++
        }
      })
      return newApplyList
    })

    const focusSelectChange = (index, val) => {
      // 印章获取焦点
      state.applyTypeIndex = index
      console.log('点击的index', state.applyTypeIndex)
    }

    //选择申请印章
    const selectChange = (value, option) => {
      console.log(' modelRef.sealFlowRelAddDTOList', modelRef.sealFlowRelAddDTOList)
      console.log('value', typeof value)
      console.log('option', option)
      console.log('获取到的印章列表', state.applyTypeList)
      console.log('所有的审批流程', state)
      console.log('审批判断', option.item.sealFlowRelVOList ?? 1)

      //获取当前选中的印章的盖印人
      let currentSelectSeal = state.applyTypeList.filter(item => item.sealId === value)
      console.log('当前选中的印章', currentSelectSeal)
      modelRef.sealFlowRelAddDTOList[option.index].affixedStaffName = currentSelectSeal[0].affixedStaffName
        ? currentSelectSeal[0].affixedStaffName
        : '暂无盖印人'
      // modelRef.sealFlowRelAddDTOList[option.index].sealId = value
      modelRef.sealFlowRelAddDTOList[option.index].isSelectSeal = true //第一条印章的显示
      state.applyProcessList = option.item.sealFlowRelVOList //当前印章的所有审批流
        ? option.item.sealFlowRelVOList
        : store.state.globalData.applyProcessList
      console.log('当前印章的所有审批流', state.applyProcessList)

      // 为当前添加审批流程
      modelRef.sealFlowRelAddDTOList[state.applyTypeIndex].approvalProcess =
        option.item.sealFlowRelVOList ?? state.applyProcessList

      if (option.item.sealFlowRelVOList) {
        if (option.item.sealFlowRelVOList.length === 1) {
          //单审批流绑定
          modelRef.sealFlowRelAddDTOList[option.index].disabled = true
        } else {
          //多审批流绑定
          modelRef.sealFlowRelAddDTOList[option.index].disabled = false
        }
        modelRef.sealFlowRelAddDTOList[option.index].flowId = option.item.sealFlowRelVOList[0].flowId
        handleProcessChange(modelRef.sealFlowRelAddDTOList[option.index].flowId, option)
        modelRef.sealFlowRelAddDTOList[option.index].sealStaffRelVOList = option.item.sealStaffRelVOList
      } else {
        // 无审批流程绑定
        modelRef.sealFlowRelAddDTOList[option.index].disabled = false
        modelRef.sealFlowRelAddDTOList[option.index].flowId = undefined
        state.approvalStaffList[option.index] = []
      }
      formRef.value.clearValidate([['sealFlowRelAddDTOList', option.index, 'flowId']])
    }
    // 获取审批流程
    const getFingerProcess = () => {
      state.applyProcessList = store.state.globalData.applyProcessList
      state.allApplyProcessList = store.state.globalData.applyProcessList
    }

    //点击删除多印章
    const handledDelete = i => {
      modelRef.sealFlowRelAddDTOList.splice(i, 1)
      state.approvalStaffList.splice(i, 1)
    }

    const numToZh = index => {
      let numZ = Math.floor(index / 10),
        numY = index % 10,
        numS
      if (numZ > 0) {
        numS = numZ == 1 ? state.numZh[10] : state.numZh[numZ] + state.numZh[10]
        if (numY > 0) {
          numS = numS + state.numZh[numY]
        }
      } else {
        numS = state.numZh[numY]
      }
      return numS
    }
    const dataTimeChange = (value, dateString) => {
      console.log(value)
      console.log('dateString', dateString)
      if (dateString[0] && dateString[1]) {
        modelRef.timeRanges = dateString
        console.log('时间', modelRef.timeRanges)
        modelRef.startTime = dateString[0] + ':00'
        modelRef.finishTime = dateString[1] + ':00'
      } else {
        modelRef.timeRanges = []
        modelRef.startTime = ''
        modelRef.finishTime = ''
      }
    }
    const addressChange = value => {
      console.log('选择外带地址', value)
      modelRef.address = value.name
      modelRef.lon = value.lng
      modelRef.lat = value.lat
      state.selectAddress = value
    }
    // 限制日期
    const range = (start, end) => {
      const result = []
      for (let i = start; i < end; i++) {
        result.push(i)
      }
      return result
    }
    const disabledDate = current => {
      return current < moment().subtract(1, 'day')
    }
    const disabledRangeTime = (_, type) => {
      let hours = moment().hours() //0~23
      let minutes = moment().minutes() //0~59
      state.defaultTime = hours + ':' + minutes
      let date = moment().date()
      let minLast, hourLast, endDate, startDate
      if (modelRef.timeRanges.length) {
        console.log(modelRef.timeRanges)
        hourLast = Number(modelRef.timeRanges[0].split(' ')[1].split(':')[0])
        minLast = Number(modelRef.timeRanges[0].split(' ')[1].split(':')[1])
        startDate = modelRef.timeRanges[0].split(' ')[0].split('-')[2]
        endDate = modelRef.timeRanges[1].split(' ')[0].split('-')[2]
      } else {
        hourLast = hours
        minLast = minutes
        endDate = date
        startDate = date
      }
      if (type === 'start') {
        let startRange = {}
        if (startDate != date) {
          startRange = {}
        } else {
          startRange = {
            disabledHours: () => range(0, hours),
            disabledMinutes: () => range(0, minutes)
          }
          if (hours != hourLast) {
            delete startRange['disabledMinutes']
          }
        }
        return startRange
      }
      if (type === 'end') {
        let endRange = {}
        if (endDate != startDate) {
          endRange = {}
        } else {
          endRange = {
            disabledHours: () => range(0, hours),
            disabledMinutes: () => range(0, minLast + 1)
          }
          if (hours != hourLast) {
            delete endRange['disabledMinutes']
          }
        }
        return endRange
      }
    }
    const openChange = status => {
      console.log('时间选择器openChange-status的值', status)
      checkedPick.value = status
      console.log('checkedPick', checkedPick)
    }

    const submitModel = value => {
      console.log('submitModel-value', value)
      modelRef.remoteStaffList = [value]
    }
    watch(
      () => modelRef.takeout,
      newValue => {
        if (newValue) {
          rulesRef.timeRanges = [
            {
              type: 'array',
              required: true,
              message: '请选择起始时间'
            },
            {
              type: 'array',
              validator: (rule, value, cbfn) => {
                if (value[0] == value[1]) {
                  return Promise.reject('开始时间和结束时间不能相同')
                }
                return Promise.resolve()
              }
            }
          ]
          rulesRef.address = [
            {
              required: true,
              message: '请选择外带地点'
            }
          ]
        } else {
          rulesRef.timeRanges = []
          rulesRef.address = []
          modelRef.address = ''
          modelRef.timeRanges = []
          modelRef.startTime = ''
          modelRef.finishTime = ''
        }
      }
    )

    watch(
      () => modelRef.remote,
      newValue => {
        if (newValue === 2) {
          rulesRef.remoteStaffList = [
            {
              required: true,
              message: '请选择远程确认人'
            }
          ]
        } else {
          rulesRef.remoteStaffList = []
          modelRef.remoteStaffList = []
        }
      }
    )
    watch(
      () => modelRef.remoteStaffList,
      newValue => {
        console.log('监测的新值', newValue)
        if (newValue?.length) {
          modelRef.remoteStaffId = newValue[0]?.id
        } else {
          modelRef.remoteStaffId = newValue?.length && newValue[0].id
        }
        formRef.value.validateFields('remoteStaffList')
      }
    )
    watch(
      () => [modelRef.address],
      () => {
        formRef.value.validateFields('address')
      }
    )

    //重新提交获取回显详情
    const getEcho = async () => {
      console.log('当前申请记录', route.query.documentId)
      const res = await getEchoDetail({ id: route.query.documentId })
      if (res.success) {
        console.log('回显的详情', res)
        //文件id
        fileId.value = res.data.id
        //流程主题
        modelRef.fileName = res.data.fileName
        //印章信息【多】
        if (res.data.sealFlowRelAddDTOList) {
          let hasEmpty = false
          modelRef.sealFlowRelAddDTOList = res.data.sealFlowRelAddDTOList
          console.log('modelRef.sealFlowRelAddDTOList', modelRef.sealFlowRelAddDTOList)

          modelRef.sealFlowRelAddDTOList.forEach(function(item, index) {
            console.log('item', item)
            console.log('state', state)
            // 审批流程
            // item.approvalProcess = item.sealFlowRelVOList ?? state.applyProcessList
            item.approvalProcess = item.sealFlowRelVOList ?? state.allApplyProcessList
            //盖印人
            // item.affixedStaffName = item.affixedStaffName ? item.affixedStaffName : '暂无盖印人'
            // item.sealStaffRelVOList =
            // console.log('印章数据', item)
            item.isSelectSeal = true
            if (!item.flowUsers.length) {
              item.flowId = null
              hasEmpty = true
            }
            state.approvalStaffList[index] = item.flowUsers //审批流程人员
            console.log('state.approvalStaffList[index]', state.approvalStaffList[index])
            if (item.bindFlow && item.sealFlowRelVOList.length === 1) {
              //单审批流
              item.disabled = true
            } else {
              //多审批流/无审批流
              state.applyProcessList = item.bindFlow ? item.sealFlowRelVOList : store.state.globalData.applyProcessList
              item.disabled = false
            }
          })
          if (hasEmpty)
            notification['error']({ message: '提示', description: '当前审批流程没有审批人,请联系管理员添加审批人' })
          modelRef.sealFlowRelAddDTOList = modelRef.sealFlowRelAddDTOList.filter(it => it.flowId)
          if (!modelRef.sealFlowRelAddDTOList.length) {
            modelRef.sealFlowRelAddDTOList = [
              {
                sealId: undefined,
                flowId: undefined,
                applicationNumber: undefined,
                isSelectSeal: false,
                disabled: false
              }
            ]
          }

          console.log('多印章列表', modelRef.sealFlowRelAddDTOList)
        }
        //是否外带 2外带使用  1本部使用
        modelRef.takeout = res.data.takeout === 2 ? true : false

        //起始时间
        console.log('起始时间', Object.prototype.toString.call(modelRef.timeRanges))
        if (res.data.startTime && res.data.finishTime) {
          modelRef.timeRanges = [res.data.startTime, res.data.finishTime]
          modelRef.startTime = res.data.startTime
          modelRef.finishTime = res.data.finishTime
        }
        //外带地点
        modelRef.address = res.data.address
        modelRef.lon = res.data.lon
        modelRef.lat = res.data.lat
        //盖印方式
        if (res.data.remote === 1) {
          modelRef.remote = 1
        } else if (res.data.remote === 2) {
          modelRef.remote = 2
        } else if (res.data.remote === 3) {
          modelRef.remote = 3
        }
        //如果是简易款，盖印方式默认展示常规
        if (store.state.user.userInfo.result.isOnlyYDA_J) {
          modelRef.remote = 1
        }
        //远程确认人
        modelRef.remoteStaffList = res.data.remoteStaffList
        // 预计用印日期
        modelRef.sealTime = res.data.sealTime
        //申请事由
        modelRef.applyText = res.data.applyText
        //上传附件
        modelRef.documentApplyFile = res.data.documentApplyFile?.map(item => ({
          name: `${item.fileName}.${item.suffix}`,
          fileId: item.fileId
        }))
        console.log('回显上传附件内容', modelRef.documentApplyFile, res.data.documentApplyFile)
      }
    }

    onMounted(() => {
      console.log('查看点击入口', typeof route.query.status)
      if (!store.state.user.userInfo.result.isOnlyYDA_J) {
        state.sealingWayList.push(
          {
            index: 2,
            sealWay: '远程盖印'
          },
          {
            index: 3,
            sealWay: '连续盖印'
          }
        )
      }
      getApplyProcessList()
      if (route.query.status === '2') {
        getEcho()
      }
      getSealList()

      getProcessStaff()
      ocrJurisdiction()
    })

    return {
      ...toRefs(state),
      modelRef,
      lineFeed,
      formRef,
      rulesRef,
      validatorSealApplyCount,
      onSubmit,
      addSealType,
      handleThumbUploadChange,
      filterSealSelectList,
      focusSelectChange,
      handleProcessChange,
      check,
      onCheckAllChange,
      onChange,
      deleteApprovalFn,
      deleteRemoteStaffList,
      handleCancel,
      searchApprovalList,
      openApprovalModal,
      onChangeList,
      numToZh,
      dataTimeChange,
      addressChange,
      submitModel,
      disabledDate,
      disabledRangeTime,
      selectChange,
      checkedPick,
      openChange,
      handledDelete,
      getEcho,
      fileId,
      isSealer
    }
  }
})
</script>

<style lang="scss" scoped>
.apply-add {
  // background: #ffffff;
  padding-bottom: 56px;

  :deep(.ant-form-item) {
    margin-bottom: 0 !important;
    padding: 16px;
    padding-bottom: 8px;
  }

  //:deep(.ant-input:placeholder-shown) {
  //  width: 294px;
  //}
  //:deep(.ant-select-selector) {
  //  width: 294px;
  //}
  .add-btn {
    font-size: 14px;
    background-color: #c3161c;
    color: #ffffff;
    border: none;
    box-shadow: none;
    border-radius: 2px;

    .add-icon {
      font-size: 12px;
    }
  }

  .content {
    background: #f6f7fa;
    margin: 16px;
    padding: 16px;

    .content-tab {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .content-tab-title {
        font-size: 14px;
        font-weight: 400;
        color: #000000;
      }

      .content-delete-icon {
        width: 16px;
        height: 16px;
      }

      .content-delete-title {
        font-size: 14px;
        font-weight: 400;
        color: #f04136;
      }
    }

    .content-delete {
      cursor: pointer;
    }
  }

  .form-item {
    display: inline-block;
  }

  .form-row {
    display: inline-block;
  }

  .form-input {
    width: 294px;
  }

  :deep(.ant-badge-count) {
    min-width: 14px;
    height: 14px;
    padding: 0;
    font-size: 12px;
    line-height: 14px;
    border-radius: 7px;
    margin-right: 9px;
    margin-top: -1px;
  }

  :deep(.ant-upload-list-item-name) {
    width: 90%;
  }

  .address {
    color: #1890ff;
    font-size: 14px;
    cursor: pointer;

    i {
      vertical-align: -1px;
    }
  }

  .have-address {
    color: black;
    font-size: 14px;
    cursor: pointer;

    i {
      vertical-align: -1px;
    }
  }
}

.add-foot {
  width: calc(100vw - 208px);
  padding: 12px 24px;
  text-align: right;
  position: fixed;
  bottom: 0;
  z-index: 10;
  background: #ffffff;
  box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.03);

  .cancel-btn,
  .cancel-btn:hover,
  .cancel-btn:focus,
  .cancel-btn:active,
  .cancel-btn.active {
    color: rgba(0, 0, 0, 0.65);
    margin-right: 8px;
    border-radius: 2px;
  }

  .submit-btn {
    background-color: #c3161c;
    border-radius: 2px;
    border: none;
    color: #ffffff;
  }
}

.remove-arrow {
  display: none;
}

.dashed-line {
  border-top: 1px dashed #e9e9e9;
}

.ml-16 {
  margin-left: 16px;
}

.mr-16 {
  margin-right: 16px;
}

.removeType {
  position: relative;

  .remove-arrow {
    color: #cccccc;
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    right: -28px;
    top: 4px;
    cursor: pointer;
  }
}

:deep(.ant-radio-wrapper) {
  margin: 10px 0;

  > span {
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    color: #333333;
    line-height: 20px;
  }
}

.radio-label {
  font-size: 12px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  text-align: left;
  color: rgba(0, 0, 0, 0.25);
  line-height: 20px;
  display: inline-block;
}

.time-progess {
  min-width: 20px;
  height: 20px;
  background: #c3161c;
  border-radius: 50%;
  font-size: 14px;
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 500;
  color: #ffffff;
  line-height: 20px;
  text-align: center;
}

.search-btn {
  margin-left: 16px;
}

.cancel {
  border: 1px solid #dadada;
  margin-left: 260px;
}

:deep(.ant-input-textarea-show-count::after) {
  position: relative;
  top: -25px;
  right: 5px;
  height: 0;
}

.card-style {
  // min-width: 1046px;
  border-top: 0;

  :deep(.ant-card-head-title) {
    padding-top: 0;
  }
}

:deep(.ant-card-body) {
  padding: 8px;
}

:deep(.ant-card-extra) {
  padding: 0;
}

.approval-modal {
  border: 1px solid rgba(0, 0, 0, 0.06);
}

.add-remote {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #e9e9e9;
  font-size: 18px;
  text-align: center;
  padding: 0;
  color: #666666;
  border: none;
  margin-bottom: 8px;
}

.scroll {
  overflow-y: scroll;
  height: 376px;

  &::-webkit-scrollbar {
    display: block;
    border-radius: 4px;
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    width: 8px;
    background: #dddddd;
    border-radius: 4px;
  }
}

.no-data {
  height: 355px;

  .no-icon {
    height: 355px;
    transform: translateY(36%);
  }
}

.checked-pick {
  :deep(.ant-calendar-picker-input) {
    border: 1px solid #c3161c !important;
  }
}
</style>

<style lang="less" scoped>
:deep(.ant-card-bordered) {
  border-bottom: none;
}

.sealer-input {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// 盖印人
.sealer-list {
  width: 100%;
  height: 32px;
  background: rgba(0, 0, 0, 0.04);
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;

  .list-box {
    width: calc(100% - 32px);
    height: 24px;
    margin: auto;
    overflow: hidden;
    white-space: nowrap;

    .item-box {
      max-width: 84px !important;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px;
      padding: 0 4px;
      background: rgba(0, 0, 0, 0.04);
      border-radius: 2px;
      border: 1px solid rgba(0, 0, 0, 0.15);
      margin-right: 8px;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

// 蒙层展示
.popover-box {
  max-width: 300px;

  .popover-item {
    // max-width: 84px!important;
    display: inline-block;
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;

    span {
      max-width: 84px !important;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
